import { useCachedCreditPricingTable } from './useCreditPricingTable'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { getCreditBalance } from '@/service/pay.service'
import {
  CreationModeEnum,
  CreditSpendProduct,
  CreditTopupProduct,
} from '@/types'
import useAuth0Auth from './useAuth0Auth'
import { CREATE_MODE_ALIAS } from '@/constants'
import { whisper } from '@/utils'

interface CreditModal {
  creditBalance?: number
  productsList: CreditTopupProduct[]
  spendProductsList: CreditSpendProduct[]
  refreshCredit?: Function
}

const CreditContext = createContext<CreditModal>({
  creditBalance: undefined,
  productsList: [],
  spendProductsList: [],
})

export interface GetCreditPriceParams {
  mode: CreationModeEnum | string
  duration: number
  resolution: number | null
}

export const CreditProvider = CreditContext.Provider

export const useCreditValue = () => {
  const { data: pricingTable } = useCachedCreditPricingTable()
  const [availableCredits, setAvailableCredits] = useState(0)
  const { isLogin } = useAuth0Auth()

  const initCreditBalance = async () => {
    const credit = await getCreditBalance()
    if (credit) {
      setAvailableCredits(credit.available_credits || 0)
    }
  }

  useEffect(() => {
    if (isLogin) {
      initCreditBalance()
    }
  }, [isLogin])

  const refreshCredit = async () => {
    const credit = await getCreditBalance()
    if (credit) {
      setAvailableCredits(credit.available_credits || 0)
    }
  }

  return {
    creditBalance: availableCredits,
    productsList: pricingTable?.credit_topup_products || [],
    spendProductsList: pricingTable?.credit_spend_products || [],
    refreshCredit,
  }
}

const useCredit = () => {
  const data = useContext(CreditContext)

  const varifyCredit = useCallback(
    (spend: number | 'free') => {
      if (spend === 'free') {
        return true
      }
      return (data.creditBalance || 0) >= spend
    },
    [data],
  )

  const getCreditPrice = useCallback(
    ({ mode, duration, resolution }: GetCreditPriceParams) => {
      const generationSpu = data.spendProductsList.find(
        (item: CreditSpendProduct) => {
          return (
            item.spu === (CREATE_MODE_ALIAS[mode as CreationModeEnum] || mode)
          )
        },
      )

      if (generationSpu) {
        let currentSku = null
        if (mode === 'CreateImg' || mode === 'generation/txt2img') {
          // return 2
          currentSku = generationSpu.skus.find(
            (item) => item.number === 4 && item.resolution === resolution,
          )
        } else {
          currentSku = generationSpu.skus.find(
            (item) => item.second === duration,
          )
        }
        if (!currentSku || currentSku.amount === 0) {
          return 'free'
        }
        return currentSku.amount
      }
      return 'free'
    },
    [data],
  )

  return { ...data, varifyCredit, getCreditPrice }
}

export default useCredit
