import { Switches } from '@/types'
import { useCachedConfig } from './useConfig'

// const useSwitches = () => {
//   return useConfig<Switches>('switches')
// }

export const useCachedSwitches = () => {
  return useCachedConfig<Switches>('switches')
}

// export default useSwitches
