import { Plan } from '@/types'
import usePlans from './usePlans'
import { useCachedSubscription } from './useSubscription'

export interface UseActivePlanResult {
  data: Plan | null
  loading: boolean
}

const useActivePlan = (): UseActivePlanResult => {
  const { data: subscription, loading: subscriptionLoading } =
    useCachedSubscription()
  const { data: plans, loading: plansLoading } = usePlans()

  const loading = subscriptionLoading || plansLoading
  const activePlan =
    plans?.find(
      (plan) =>
        !!plan.product_id &&
        plan.product_id === subscription?.user_current_plan?.product,
    ) ?? null

  return {
    data: activePlan,
    loading,
  }
}

export default useActivePlan
