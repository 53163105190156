import { Plan } from '@/types'
import { useMemo } from 'react'
import { useCachedPricingTable } from './usePricingTable'
import { DEFAULT_CURRENCY, MEMBERSHIP_TIER_IDS } from '@/constants'
import { useCachedSubscription } from './useSubscription'

const tierId2Features: Record<
  string,
  Array<{ name: string; included: boolean; tips?: string }>
> = {
  [MEMBERSHIP_TIER_IDS.FREE]: [
    {
      name: '5 creations per day',
      included: true,
    },
    {
      name: '300 credits',
      included: true,
      tips: '300 credits when you register',
    },
    {
      name: '3 concurrent creations',
      included: true,
    },
    {
      name: 'No watermark-free videos',
      included: false,
    },
    {
      name: 'No commercial use',
      included: false,
    },
    {
      name: 'No private creation',
      included: false,
    },
  ],
  [MEMBERSHIP_TIER_IDS.EXPLORER]: [
    {
      name: 'Unlimited basic video creations (beta)',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '1500 non-expiring credits per month',
      included: true,
      tips: '1500 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: '5 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features (limited free)',
      included: true,
    },
    {
      name: 'No watermark-free videos',
      included: false,
    },
    {
      name: 'No commercial use',
      included: false,
    },
    {
      name: 'No private creation',
      included: false,
    },
  ],
  [MEMBERSHIP_TIER_IDS.PRO]: [
    {
      name: 'Unlimited basic video creations (beta)',
      included: true,
      tips: '2s and 4s videos in 540p or 720p',
    },
    {
      name: '5000 non-expiring credits per month',
      included: true,
      tips: '5000 credits added to your account monthly, the credits won’t expire',
    },
    {
      name: '10 concurrent creations',
      included: true,
    },
    {
      name: 'Early access to new features (more features are coming soon)',
      included: true,
    },
    {
      name: 'Create watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
  [MEMBERSHIP_TIER_IDS.ENTERPRISE]: [
    {
      name: 'Creations on demand',
      included: true,
    },
    {
      name: 'More concurrent creations',
      included: true,
    },
    {
      name: 'Customized functions',
      included: true,
    },
    {
      name: 'Watermark-free videos',
      included: true,
    },
    {
      name: 'Commercial use',
      included: true,
    },
    {
      name: 'Private creation',
      included: true,
    },
  ],
}

const usePlans = (): {
  data: Plan[]
  loading: boolean
} => {
  const { data: pricingTable, isValidating: pricingTableLoading } =
    useCachedPricingTable()
  const { data: subscription, loading: subscriptionLoading } =
    useCachedSubscription()

  const loading = pricingTableLoading || subscriptionLoading

  const currency = useMemo(() => {
    return (
      subscription?.subscriptions?.filter((e) => e.status === 'active')?.[0]
        ?.currency ?? DEFAULT_CURRENCY
    )
  }, [subscription])

  const plans: Plan[] = useMemo(() => {
    const enterprisePlan: Plan = {
      name: 'Enterprise APIs',
      tier_id: MEMBERSHIP_TIER_IDS.ENTERPRISE,
      tier_name: 'Enterprise APIs',
      max_queue_size: Number.MAX_SAFE_INTEGER,
      max_concurrent_tasks: Number.MAX_SAFE_INTEGER,
      max_generation_tasks: Number.MAX_SAFE_INTEGER,
      allow_private_generation: true,
      allow_watermark_free_download: true,
      features: tierId2Features[MEMBERSHIP_TIER_IDS.ENTERPRISE] ?? [],
      is_free: false,
    }

    const result =
      pricingTable?.map((pricingTableItem) => {
        const monthlyPrice = pricingTableItem.prices?.find(
          (price) =>
            price.recurring.interval === 'month' &&
            price.active &&
            (price.currency === currency || !currency),
        )
        const yearlyPrice = pricingTableItem.prices?.find(
          (price) =>
            price.recurring.interval === 'year' &&
            price.active &&
            (price.currency === currency || !currency),
        )
        return {
          ...pricingTableItem,
          name: pricingTableItem.tier_name,
          features: tierId2Features[pricingTableItem.tier_id] ?? [],
          is_free: pricingTableItem.tier_id === MEMBERSHIP_TIER_IDS.FREE,
          prices: {
            monthly: monthlyPrice?.unit_amount ?? Number.NaN,
            monthly_id: monthlyPrice?.id ?? '',
            monthly_currency: monthlyPrice?.currency ?? '',
            yearly: yearlyPrice?.unit_amount ?? Number.NaN,
            yearly_id: yearlyPrice?.id ?? '',
            yearly_currency: yearlyPrice?.currency ?? '',
          },
          product_id: pricingTableItem.product?.id ?? '',
        }
      }) ?? []

    return [...result, enterprisePlan]
  }, [pricingTable, currency])

  return {
    data: plans,
    loading,
  }
}

export default usePlans
